<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
  props: ['thisMonthStats'],
  extends: Line,
  data() {
    return {
      lineData: {
        labels: [],
        moment: moment,
        datasets: [
          {
            label: 'Clicks',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(75,192,192,1)',
            pointHoverBorderWidth: 10,
            pointRadius: 3,
            pointHitRadius: 50,
            data: [],
          },
          {
            label: 'NetClicks',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(255, 99, 132)',
            pointBackgroundColor: 'rgb(255, 99, 132)',
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBackgroundColor: 'rgb(255, 99, 132)',
            pointHoverBorderColor: 'rgb(255, 99, 132)',
            pointHoverBorderWidth: 10,
            pointRadius: 3,
            pointHitRadius: 50,
            data: [],
          },
          {
            label: 'Spent',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgb(54, 162, 235)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderWidth: 1,
            pointHoverRadius: 1,
            pointHoverBackgroundColor: 'rgb(54, 162, 235)',
            pointHoverBorderColor: 'rgb(54, 162, 235)',
            pointHoverBorderWidth: 10,
            pointRadius: 3,
            pointHitRadius: 50,
            data: [],
          },
        ],
      },
      lineOptions: {
        tooltips: {
          mode: 'label',
        },
        title: {
          display: true,
          position: 'bottom',
          text: moment().format('MMMM'),
        },
        responsive: true,
      },
    }
  },
  mounted() {
    this.lineData.labels = this.thisMonthStats.Labels
    this.lineData.datasets[0].data = this.thisMonthStats.Clicks
    this.lineData.datasets[1].data = this.thisMonthStats.NetClicks
    this.lineData.datasets[2].data = this.thisMonthStats.Spent.map(ele => ele.toFixed(2))
    this.renderChart(this.lineData, this.lineOptions)
  },
}
</script>
