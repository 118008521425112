<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Today Statistics</strong>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <dashboard-card :value="todayStatistics.totalClicks | formatNumber" :label="'TOTAL CLICKS'"
          :color="'rgba(75,192,192,1)'"></dashboard-card>
      </div>
      <div class="col-xl-4">
        <dashboard-card :value="todayStatistics.netClicks | formatNumber" :label="'NET CLICKS'"
          :color="'rgb(255, 99, 132)'"></dashboard-card>
      </div>
      <div class="col-xl-4">
        <dashboard-card
          :value="todayStatistics.spent !== 0 ? ('$ ' + Math.round((todayStatistics.spent + Number.EPSILON) * 100) / 100) : '0'"
          :label="'SPENT'" :color="'rgb(54, 162, 235)'"></dashboard-card>
      </div>
    </div>
    <div class="cui__utils__heading">
      <strong>This Month Statistics</strong>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <div class="">
                <line-chart v-if="thisMonthStats.display" :thisMonthStats="thisMonthStats" :key="renderKey"
                  :height="computedHeight" id="test" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Campaigns</strong>
            </div>
            <div class="text-muted">Last 7 days</div>
          </div>
          <div class="card-body">
            <a-table class="table-overflow-x" :columns="campaignColumns" :dataSource="campaignData" rowKey="id" :pagination="false">
              <span slot="Clicks" slot-scope="Clicks">
                {{ Clicks | formatNumber }}
              </span>
              <span slot="NetClicks" slot-scope="NetClicks">
                {{ NetClicks | formatNumber }}
              </span>
              <span slot="Spent" slot-scope="Spent">
                ${{ parseInt(Spent).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="cui__utils__heading mb-0">
              <strong>Feeds</strong>
            </div>
            <div class="text-muted">Last 7 days</div>
          </div>
          <div class="card-body">
            <a-table :columns="pubfeedColumns" :dataSource="pubfeedData" :pagination="false">
              <span slot="Requests" slot-scope="Requests">
                {{ Requests | formatNumber }}
              </span>
              <span slot="AdResponses" slot-scope="AdResponses">
                {{ AdResponses | formatNumber }}
              </span>
              <span slot="Clicks" slot-scope="Clicks">
                {{ Clicks | formatNumber }}
              </span>
              <span slot="NetClicks" slot-scope="NetClicks">
                {{ NetClicks | formatNumber }}
              </span>
              <span slot="Spent" slot-scope="Spent">
                ${{ parseInt(Spent).toLocaleString(undefined, { minimumFractionDigits: 2 }) }}
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <div class="cui__utils__heading mb-3">
      <strong>LAST MONTH PUBLISHER SPENDING</strong>
    </div>
    <div class="">
      <cui-general-17 :xml="true" :publishersData="publisherData"></cui-general-17>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CuiGeneral17 from '@/components/kit/widgets/General/17'
import DashboardCard from '@/components/custom/dashboard/card'
import { getDashboardStats } from '@/api/xml/dashboard'
import LineChart from '@/components/custom/dashboard/chart'

const campaignColumns = [
  {
    title: 'Campaign',
    dataIndex: 'Campaign',
    key: 'Campaign',
    align: 'center',

  },
  {
    title: 'Clicks',
    dataIndex: 'Clicks',
    key: 'Clicks',
    align: 'right',
    slots: { title: 'Clicks' },
    scopedSlots: { customRender: 'Clicks' },
  },
  {
    title: 'Net Clicks',
    dataIndex: 'NetClicks',
    key: 'NetClicks',
    align: 'right',
    slots: { title: 'NetClicks' },
    scopedSlots: { customRender: 'NetClicks' },
  },
  {
    title: 'Spent',
    dataIndex: 'Spent',
    key: 'Spent',
    align: 'right',
    slots: { title: 'Spent' },
    scopedSlots: { customRender: 'Spent' },
  },
]

const pubfeedColumns = [
  {
    title: 'Feed',
    dataIndex: 'Feed',
    key: 'Feed',
    align: 'center',
  },
  {
    title: 'Requests',
    dataIndex: 'Requests',
    key: 'Requests',
    align: 'right',
    slots: { title: 'Requests' },
    scopedSlots: { customRender: 'Requests' },
  },
  {
    title: 'Ad Responses',
    dataIndex: 'AdResponses',
    key: 'AdResponses',
    align: 'right',
    slots: { title: 'AdResponses' },
    scopedSlots: { customRender: 'AdResponses' },
  },
  {
    title: 'Clicks',
    dataIndex: 'Clicks',
    key: 'Clicks',
    align: 'right',
    slots: { title: 'Clicks' },
    scopedSlots: { customRender: 'Clicks' },
  },
  {
    title: 'Net Clicks',
    dataIndex: 'NetClicks',
    key: 'NetClicks',
    align: 'right',
    slots: { title: 'NetClicks' },
    scopedSlots: { customRender: 'NetClicks' },
  },
  {
    title: 'Spent',
    dataIndex: 'Spent',
    key: 'Spent',
    align: 'right',
    slots: { title: 'Spent' },
    scopedSlots: { customRender: 'Spent' },
  },
]

export default {
  components: {
    LineChart,
    DashboardCard,
    CuiGeneral17,
  },
  computed: {
    ...mapState(['settings']),
    computedHeight: function () {
      if (this.$store.state.settings.isMobileView === true) {
        return 300
      } else {
        return 90
      }
    },
  },
  watch: {
    computedHeight: function () {
      this.renderKey = this.renderKey + 1
    },
  },
  data() {
    return {
      renderKey: 0,
      i: 1,
      publisherData: [],
      thisMonthStats: {
        display: false,
      },
      campaignData: [],
      pubfeedData: [],
      campaignColumns,
      pubfeedColumns,
      todayStatistics: {
        totalClicks: 0,
        netClicks: 0,
        spent: 0,
      },
    }
  },
  methods: {
    formatSpent(num) {
      return parseInt(num).toLocaleString()
    },
  },
  filters: {
    formatNumber(num) {
      // return Math.round((num + Number.EPSILON) * 100) / 100
      return parseInt(num).toLocaleString()
    },
  },
  beforeMount() {
    getDashboardStats().then(response => {
      if (response) {
        let i = 0
        this.campaignData = response.CampaignLast7DaysData.map(el => {
          el.key = i++
          return el
        })
        this.pubfeedData = response.PubfeedLast7DaysData.map(el => {
          el.key = i++
          return el
        })
        this.todayStatistics.totalClicks = response.TodayStats.Clicks
        this.todayStatistics.netClicks = response.TodayStats.NetClicks
        // this.todayStatistics.spent = response.TodayStats.Spent.toFixed(2)
        this.todayStatistics.spent = Number(response.TodayStats.Spent.toFixed(2))
        this.thisMonthStats = { ...response.ThisMonthStats }
        this.publisherData = response.PublisherLastMonthData
        this.thisMonthStats.display = true
      }
    }).catch(error => console.log(error))
  },

}
</script>

<style>
.table-overflow-x {
  width: 100%;
  float: left;
  overflow: hidden;
  overflow-x: scroll;
}
</style>
